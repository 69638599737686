@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.features {
  background: $gold;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 10px 0 3px;

  div {
    animation: animate 40s -40s linear infinite;
    will-change: transform;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      animation: animate2 40s -20s linear infinite;
    }

    span {
      font-weight: 700;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;

      &:before {
        content: '';
        display: inline-block;
        background: url('../img/check-mark.svg') no-repeat center center;
        background-size: 15px 15px;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        border: 2px solid $green;
        border-radius: 50%;
        padding: 2px;
      }
    }
  }
}