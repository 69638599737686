.office {
  padding-bottom: 0;

  .swiper-container {
    width: 100%;
    display: none;

    & img {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      object-fit: cover;
    }
  }

  .slider {
    margin-bottom: 20px;
  }

  & .where-we-are {
    //width: calc(100% - 20px);
    margin: 0 0 20px 10px;

    & h3 {
      color: $gold;
    }

    & p,
    & ul {
      font-size: 16px;
    }

    & p {
      margin-bottom: 38px;
      text-align: center;

      & a {
        color: white;
      }
    }

    & .rounded-box {
      border: 2px solid $gold;
      border-radius: 5px;
      padding: 20px;
      box-sizing: border-box;
      margin: 0 0 30px;
      display: flex;
      flex-flow: column;
      align-items: center;
      position: relative;
      width: 100%;

      &:last-child {
        margin: 0;
      }

      &.map {
        max-height: 250px;

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        & iframe {
          width: 100%;
        }
      }

      & .section-heading {
        margin-bottom: 20px;
      }

      & ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 2px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 9px;
          background: $gold;
        }

        & li {
          margin: 0 0 38px 0;
          padding: 0 0 0 40px;
          position: relative;
          line-height: 1.7;

          &:before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            left: 0;
            top: 0;
            background: $gold;
          }

          &:last-of-type {
            margin-bottom: 0;

            &:before {
              top: unset;
              bottom: 0;
            }
          }
        }
      }
    }
  }

  #opening-hours {
    margin: 20px auto 0;

    th {
      text-align: left;
      padding-right: 15px;
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-container {
      display: block !important;
    }
  }

  @media screen and (max-width: 1023px) {
    & .flex {
      flex-flow: column;
    }
  }

  @media screen and (min-width: 1024px) {
    & .slides,
    & .where-we-are {
      margin: 0;
      margin-bottom: 20px;
    }

    & .where-we-are {
      //margin-left: 60px;

      & .content {
        flex-grow: 1;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
      }
    }
  }
}