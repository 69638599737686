.strengths {
  & .grid .item h3 {
    font-weight: 800;
  }

  .grid {
    .item {
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .strengths {
    .grid {
      .item {
        margin-bottom: 0;
      }
    }
  }
}