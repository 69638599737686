.other {
  &.blue {
    background: url("../img/form.jpg") no-repeat center center $blue !important;
    background-size: cover;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;

    li {
      margin: 0;
      padding: 5px;
      box-sizing: border-box;
      text-align: center;
      font-weight: $bold;
      align-items: center;

      @media screen and (max-width: 767px) {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}