.call-to-action, .after-form {
  > div {
    width: 100%;

    .button {
      display: block;
    }

    &.cta-sell {
      max-width: 450px;
      margin-bottom: 20px;

      .button {
        background: #03A359;
      }
    }

    &#reviews-logos {
      margin-bottom: 20px;
    }

    &.cta-call {
      margin-bottom: 30px;
    }
  }
}

.notices a {
  background: $gold;

  &:hover {
    background: none;
  }
}

@media (min-width: 768px) {
  .call-to-action {
    > div {
      width: 50%;
    }
  }
}

.trust-logos {
  display: grid;
  grid-template-columns: repeat(5, minmax(50px, 1fr));
  background: rgba($blue, .5);

  .blue & {
    background: rgba(black, .25);
  }

  @media screen and (min-width: 768px) {
    grid-gap: 0 60px;
    padding: 0 60px;
  }

  &.verona {
    grid-template-columns: repeat(6, minmax(50px, 1fr));
  }

  margin-top: 20px;
  width: 100% !important;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
    height: auto;
    margin: auto;

    &.city {
      max-width: 60px;
    }
  }
}