.page-template-template-thankyou {
  .letter-wrapper {
    background: #fff;
    color: #474746;
    max-width: 500px;
    padding: 40px 20px;
    border-radius: 60px;
    margin-top: 60px;

    .header {
      margin-bottom: 50px;
    }

    .content {
      margin-bottom: 50px;

      .signature {
        display: flex;
        flex-direction: row;

        figure {
          margin: 10px 40px 0 0;
        }
      }
    }

    .footer {
      .button {
        background: #03A359;
        width: calc(100% - 40px);
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 768px) {
  .page-template-template-thankyou {
    .letter-wrapper {
      padding: 40px 60px;
      border-radius: 60px;
      width: 100%;

      .footer {
        .button {
          width: calc(100% - 30px);
          font-size: 1.5rem;
        }
      }
    }
  }
}