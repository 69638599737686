.main-quotation {
  .daily-offer {
    font-size: 1.125rem;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.1;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  .subtitle {
    margin-bottom: 15px;
  }

  h4 {
    font-size: 2.75rem;
    margin-top: 0;
    line-height: 1.1;
    margin-bottom: 0;
  }

  figure {
    img {
      width: 100%;
      max-width: 650px;
    }
  }

  .grid {
    figure {
      img {
        max-width: 400px;
      }
    }
  }

  .price-group {
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    border: 4px solid white;
    margin: 35px auto 0;
    padding: 20px;
    background: $gold;
    min-width: 252px;

    small {
      color: #14151F;
    }
  }
}

@media (min-width: 768px) {
  .main-quotation {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}