.page-template-template-notice {
  color: white;
  background: $blue;

  .wrapper {
    background: url("../img/form.jpg") no-repeat center;
    background-size: cover;

    a {
      padding-left: 20px;
      padding-right: 20px;

      &.button {
        color: white;
        background: $gold;
        font-weight: bold;
      }

      &:hover {
        background: none;
      }
    }
  }
}