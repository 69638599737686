.shop-archive {
  .shop {
    background: #14151F;
    border-bottom: 7px solid $gold;
    height: 40vh;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      h2 {
        margin: 0;
        position: relative;
        z-index: 2;

        &::after {
          content: '';
          display: block;
          margin: 10px auto 0px;
          height: 7px;
          border-radius: 10px;
          width: 150px;
          background: #f7b232;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .shop-archive {
    .shop {
      a {
        h2 {
          font-size: 4rem;
        }
      }
    }
  }
}