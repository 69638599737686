/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
  font-family: $open_sans;
  letter-spacing: .025em;
  line-height: 1.5;
  font-weight: $regular;
  margin: 0;
  padding: 0;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "liga", "dlig" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: $blue;
}

.middle {
  width: calc(100% - 20px);
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    max-width: 1200px;
  }
}

.section-heading {
  font-size: 24px;
  font-weight: $extra_bold;
  text-align: center;
  padding: 0;
  margin: 0;
  text-transform: uppercase;

  & a {
    color: white;
  }

  &.gold {
    color: $gold;

    & a {
      color: $gold !important;
    }
  }

  & small {
    color: white;
    font-weight: 400;
    font-size: 14px;
  }
}

p.section-heading {
  margin-bottom: 0;
  line-height: 1.2;
}

.section-subtitle {
  font-size: 14px;
  margin: 10px 0 15px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;

  &.normal {
    text-transform: none;
  }

  & strong {
    font-size: 24px;
  }
}

p.section-heading {
  //margin-bottom: 60px;
}

.separator {
  display: block;
  margin: 14px auto 14px;
  background: $gold;
  width: 55px;
  height: 10px;
  border-radius: 5px;

  &.more-margin {
    margin-bottom: 60px;
  }
}

.row {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;

  &:before,
  &:after {
    content: '';
    display: table;
    width: 100%;
    clear: both;
  }

  & .content > h2 {
    color: $gold;
  }

  &.white {
    background: white;
    color: $blue;

    & p a {
      color: $blue;
    }
  }

  &.blue {
    background: $blue;
    color: white;
  }

  &.border-top {
    border-top: 7px solid $gold;
  }

  &.gold {
    background: $gold;
    color: white;
    width: 100%;
    float: left;

    & h2 {
      color: white;
    }

    & .separator {
      background: white;
    }
  }
}

.grid {
  display: grid;
  margin: 20px 0;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  &.by-2 {
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }

  &.by-4 {
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
    }
  }

  & .item {
    display: flex;
    align-items: center;
    flex-flow: column;

    & h3 {
      font-weight: $semi_bold;
      font-size: 18px;
      margin: 0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
    }



    & img {
      margin: 30px 0;
    }

    & p {
      font-weight: $semi_bold;
      font-size: 16px;
      margin: 0;
      text-align: center;
    }
  }
}

.background-red {
  background: red;
  color: white;
  font-weight: $bold;
  padding: 0 5px;
}

.background-yellow {
  background: #b5bb02;
  color: white;
  font-weight: $bold;
  padding: 0 5px;
}
