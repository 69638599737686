#opening-hours {
  margin: 20px auto 0;
}

#opening-hours th {
  text-align: left;
  padding-right: 15px;
}

.office .where-we-are {
  width: 100%;
  margin-left: 0 !important;
  margin-bottom: 20px !important;
}

.office .slider {
  margin-bottom: 20px;
}

.single-shop .gold-bg {
  background: #f7b232;
  color: #14151F;
  padding: 10px 30px;
  margin: 0 auto;
  display: inline-block;
  width: auto;
  font-weight: bold;
  font-size: 12px !important;
}


.single-shop .gold-bg a {
  color: #14151F;
}

.call-to-action > div.cta-sell, .after-form > div.cta-sell, {
  margin-bottom:20px;
}

.call-to-action > div#reviews-logos, .after-form > div#reviews-logos {
  margin-bottom: 20px; }
.call-to-action > div.cta-call, .after-form > div.cta-call {
  margin-bottom: 30px; }

.main-quotation h3 {
  margin-bottom: 0;
}

.main-quotation .subtitle {
  margin-bottom:15px;
}

@media screen and (min-width: 768px){
  .notices > div {
    width: auto;
  }

  .notices {
    justify-content: center;
    align-items: center;
  }


  .single-shop .gold-bg {
    font-size: 16px !important;
  }
}

.form .notices {
  margin-left: auto;
}


