@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(248, 140, 0, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(248, 140, 0, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(248, 140, 0, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(248, 140, 0, 0);
  }
}

.pulse {
  position: relative;
  box-shadow: 0 0 0 0 rgba(248, 140, 0, 0.7);
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.button {
  display: inline-block;
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  font-weight: $extra_bold;
  padding: 13px;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.25rem;
  }
}