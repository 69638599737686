.notices {
  display: block;

  > div {
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;

    a {
      color: $blue;
      text-decoration: none;
      border: 2px solid #f7b232;
      border-radius: 6px;
      padding: 5px 15px;
      font-weight: bold;
      text-align: center;
      display: block;

      &:hover {
        color: $gold;
      }
    }
  }
}

@media (min-width: 768px) {
  .notices {
    display: flex;

    > div {
      width: 50%;
      padding: 0 10px;

      a {
        display: inline-flex;
      }

      &:first-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}