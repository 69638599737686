.home {
  .header {
    border-bottom: 7px solid $gold;
    background: #14151F;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      position: relative;
      padding-bottom: 20px;

      .logo {
        margin: 0;
      }
    }
  }
}

.admin-bar {
  .sticky-header {
    &.show {
      top: 32px;
      transition:all 1s ease;
    }
  }
}

.sticky-header {
  padding: 15px 0;
  background: rgba(0,0,0,0.5);
  position: fixed;
  width: 100%;
  z-index: 999;
  top: -100px;
  transition:all 1s ease;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.show {
    top: 0;
    transition:all 1s ease;
  }

  > div {
    margin: 0 10px;

    &.cta-sell {
      .button {
        background: #03A359;
      }
    }

    &.cta-call {
      .button {
        background: #1650D5;
      }
    }
  }

  a {
    &.button {
      padding: 13px 15px;
      font-size: 0.7rem;
      max-width: calc(100% - 30px);
    }
  }
}
