.places {
  padding-bottom: 0 !important;

  .grid {
    grid-gap: 0;
    margin-bottom: 0;

    .item {
      position: relative;

      figure {
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin: 0;
        }
      }

      h3 {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.75);
        text-transform: unset;
        padding: 10px 0;
        font-weight: bold;
      }
    }
  }
}