.single-shop {
  .hero {
    &.row {
      padding: 40px 0;
    }

    .content {
      .top-row {
        @media screen and (max-width: 767px) {
          display: flex;
          flex-flow: column;

          .logo {
            order: 1;
          }

          .call {
            order: 2;
          }

          .call:first-of-type {
            order: 3;
          }
        }

        text-align: center;

        .logo {
          margin-bottom: 40px;
          @media screen and (min-width: 768px) {
            width: calc(100% / 3);
          }

          img {
            width: clamp(200px, 100%, 228px);
            height: auto;
          }
        }

        .call {
          margin-bottom: 40px;
          @media screen and (min-width: 768px) {
            width: calc(100% / 3);
          }

          .line {
            display: block;
          }

          a {
            font-size: 2rem;
            @media screen and (min-width: 768px) {
              font-size: clamp(2.3rem, 4vw, 3rem);
            }

            color: $gold;
          }

          p {
            color: $gold;
            font-weight: bold;
          }
        }
      }

      .offer {
        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;

          @media screen and (max-width: 767px) {
            flex-flow: column;
          }
        }

        p {
          font-size: 1.125rem;

          &:last-child {
            margin-bottom: 30px;
          }
        }

        h2 {
          font-size: 2rem;
          margin: 0;

          .material-offer-wrapper {
            margin-bottom: 15px;
            line-height: 1.1;
          }

          .price-offer {
            font-size: 3.5rem;
            line-height: 1;
          }

          .unit-offer {
            font-size: 1rem;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .single-shop {
    .hero {
      .content {
        .top-row {
          .call {
            display: block;
          }
        }

        .offer {
          h2 {
            .price-offer {
              font-size: 5rem !important;
            }
          }

          .flex h2 .price-offer {
            font-size: 4rem !important;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .single-shop {
    .hero {
      &.row {
        padding: 70px 0;
      }

      .content {
        .top-row {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .call {
            text-align: center;
            margin-bottom: 10px;


          }
        }
      }
    }
  }
}

.single-shop {
  .hero {
    .call.where.custom-align {
      p {
        margin: 8px 0 0 0;

        span {
          display: block;
          margin: 8px 0 0 0;
          font-size: 88% !important;
        }
      }
    }

    h2.flex {
      display: flex;
      flex-flow: column;

      .grouped {
        display: flex;
        flex-flow: column;
        margin: 0 auto 30px;
        padding: 20px;
        background: $gold;
        border: 4px solid white;

        span {
          color: white !important;
        }
        small {
          color: $blue !important;
          margin-bottom: 0 !important;
        }
      }
    }

    .flex .grouped {
      @media screen and (min-width: 768px) {
        width: 252px;
      }
    }
  }
}

/*
.hero {
  .new-box {
    padding: 20px;
    background: #f7b232;
    border: 4px solid white;
    color: white;
    margin: 0 auto 30px;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 570px;
  }
}
*/