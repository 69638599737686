.how-it-works {
  & .grid .item h3 {
    font-weight: 800;
  }

  .grid {
    .item {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .section-heading {
    small {
      color: black;
    }
  }

  .strong-point {
    figure {
      position: relative;
      margin: 0;
      width: 100%;
      text-align: center;

      span {
        background: #14151F;
        width: 24px;
        height: 24px;
        border-radius: 20px;
        position: absolute;
        bottom: 20px;
        right: 30%;
      }
    }
  }

  .notices {
    > div {
      a {
        color: black;
      }
    }
  }
}