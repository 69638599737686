@charset "UTF-8";
@import 'common/normalize';

$open_sans: 'Open Sans', sans-serif;
$regular: 400;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;

$blue: #14151F;
$gold: #f7b232;
$red: #B9151C;
$green: #03A359;

@import 'common/base';
@import 'common/helper';
@import "common/icons";
@import 'components/jquery.fancybox';
@import 'components/flickity';
@import "components/forms";
@import "components/testimonials";
@import "components/office";
@import "components/additional";
@import "components/how-it-works";
@import "components/places";
@import "components/quotation";
@import "components/buttons";
@import "components/notices";
@import "components/call-to-action";
@import "components/other";
@import "components/hero";
@import "components/strengths";
@import "components/video";
@import "components/features";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/top-bar";
@import "layouts/pages";
@import "layouts/thankyou-page";
@import 'main';
@import "home";
@import "ex-inline";


