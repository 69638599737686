.form,
#form-step-2 {
  background: url("../img/form.jpg") no-repeat #14151F !important;
  background-size: cover !important;

  & p.description {
    font-weight: $semi_bold;
    font-size: 16px;
    margin: 40px 0 35px;
    padding: 0;
    text-align: center;

    & em {
      font-weight: $regular;
      font-style: italic;
    }
  }

  & h3 {
    margin-bottom: 50px;
  }

  & form {
    max-width: 450px;
    margin: 0 auto;

    & label.error {
      display: none !important;
    }

    & p {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      max-width: 450px;
      text-align: center;
    }

    .select-wrapper {
      .wpcf7-form-control-wrap {
        width: 100%;
        display: block;
        max-width: 450px;
        height: 60px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        border: 2px solid $gold;
        border-radius: 5px;
        background: white;
        font-size: 16px;
        margin: 0 auto 35px;
        font-weight: $bold;
        color: $blue;
        position: relative;
        overflow: hidden;

        select {
          position: absolute;
          top: 50%;
          transform: translate(20px, -50%);
          left: 30px;
          width: 120%;
          z-index: 1;
          background: none;
          outline: none;
          border: none;
          font-weight: $bold;
          font-size: 16px;
          color: $blue;
          appearance: none;
        }
      }
    }

    & input/*,
    & .select-wrap*/ {
      width: 100%;
      max-width: 450px;
      height: 60px;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px;
      border: 2px solid $gold;
      border-radius: 5px;
      background: white;
      font-size: 16px;
      margin: 0 auto 35px;
      font-weight: $bold;
      color: $blue;

      &.error,
      &.wpcf7-not-valid {
        border: 2px solid red;
      }
    }

    & input {
      &::placeholder {
        font-weight: $bold;
        font-size: 16px;
        color: $blue;
      }
    }

    /*& .select-wrap {
      position: relative;
      overflow: hidden;

      & .time {
        display: block;
      }

      & select {
        position: absolute;
        top: 50%;
        transform: translate(20px, -50%);
        left: 0;
        width: 120%;
        z-index: 1;
        background: none;
        outline: none;
        border: none;
        font-weight: $bold;
        font-size: 16px;
        color: $blue;
        appearance: none;
      }

      & .down {
        border: solid $blue;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: 40%;
        z-index: 2;
      }
    }*/

    input[type='text'],
    input[type="tel"],
    .select-wrapper .what {
      padding-left: 50px;
      background-repeat: no-repeat;
      background-position: 15px center;
    }

    input[type="text"] {
      background-image: url("../img/people.svg");
      background-size: 25px 43px;
    }

    input[type="tel"] {
      background-image: url("../img/mobile-phone.svg");
      background-size: 25px 43px;
    }

    .select-wrapper .what,
    .wpcf7-form-control-wrap[data-name="what"] {
      background-image: url("../img/balance-icon.svg");
      background-size: 35px 35px;
      background-repeat: no-repeat;
      background-position-x: 10px;
      background-position-y: 11px;
    }

    input[name="what-altro"] {
      background-image: url("../img/question-mark.svg");
      background-size: 25px 43px;
    }

    .button {
      background: #03A359;
      width: 100%;
    }
  }
}

.form-disclaimer {
  font-size: 12px;
  font-weight: $semi_bold;
  letter-spacing: 0;
  max-width: unset !important;
  text-align: center;
  margin-bottom: 35px !important;
  margin-top: 70px;

  & strong {
    font-weight: $bold;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  & .gold {
    color: $gold;
  }
}

/*#form-step-2 {
  padding: 30px;
  box-sizing: border-box;
  display: none;
  color: white;
  text-align: center;
  max-width: 1200px;
  margin-left: 20px;
  margin-right: 20px;

  & p {
    text-align: left;
    font-size: 16px;
    font-weight: $semi_bold;
    margin-bottom: 35px;
    max-width: unset;
  }
}*/

span.wpcf7-not-valid-tip {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .form {
    .notices {
      margin-left: -70px;
    }
  }
}

.wpcf7 input[type="checkbox"] {
  width: auto;
  max-width: unset;
  height: unset;
  display: inline;
  float: none;
  margin: 0;
}
.form-disclaimer a {
  color: white;
}