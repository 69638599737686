.top-bar {
  background: $gold;
  color: #14151F;
  margin: 0;
  padding: 10px 0 !important;
  line-height: 1.2;
  font-size: 12px;
  font-weight: $bold;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .top-bar {
    ul {
      li {
        display: inline-block;

        &:first-child {
          &:after {
            content: "|";
          }
        }
      }
    }
  }
}


.press-icons {
  width: 100%;
  background: $blue;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    display: inline-flex;
    color: $gold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;

    img {
      display: flex;
      width: 70px;
      height: auto;
      margin: 5px;

      @media screen and (max-width: 767px) {
        display: none;
        margin: 3px;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          display: flex;
        }
      }
    }
  }
}