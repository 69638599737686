.testimonials,
#testimonials {
  & h2 {
    color: $blue;
    font-weight: $bold;
  }

  & .carousel {
    margin: 100px 0;
  }

  & .carousel-cell {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: calc(100% / 3);
    }

    & iframe,
    & video {
      width: 100%;
      height: auto;
      aspect-ratio: 640 / 360;
      object-fit: cover;
    }

    @media  screen and (max-width: 767px) {
      & p br {
        display: none;
      }
    }
  }

  & .testimonial {
    padding: 0 10px;
    letter-spacing: 0;
    width: 100%;
    box-sizing: border-box;

    & blockquote {
      margin: 0 0 20px 0;
      padding: 0;
      font-size: 18px;
      font-style: italic;
      text-align: center;

      & p {
        margin: 0 0 20px 0;
        padding: 0;
      }

      & cite {
        margin: 0 0 20px 0;
        padding: 0;
      }
    }

    & .rating {
      text-align: center;
      display: block;

      & strong {
        color: $gold;
      }
    }
  }

  .section-heading {
    small {
      color: black;
    }
  }

  .notices {
    > div {
      a {
        color: #2C1A17;
      }
    }
  }
}