.footer {
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;

  &.row {
    padding: 30px 0;
  }

  & a {
    color: white;
    text-decoration: none;
  }

  & h5 {
    font-size: 16px;
    font-weight: $bold;
    text-transform: uppercase;
    margin-bottom: 0;
    color: $gold;
  }

  & p {
    margin-top: 0;
  }

  .notices {
    display: block;
    text-align: center;

    > div {
      display: inline-block;
      width: auto;
    }
  }

  .grid {
    margin-bottom: 0;

    > div {
      margin-bottom: 20px;

      .menu {
        list-style: none;
        padding-left: 0;
        margin: 0;

        li {
          a {
            color: $gold;
          }
        }
      }
    }
  }

  .trust-logos {
    display: grid;
    grid-template-columns: repeat(4, minmax(50px, 1fr));
    background: rgba($blue, .5);
    @media screen and (min-width: 768px) {
      grid-gap: 0 60px;
      padding: 0 60px;
    }
    max-width: 800px;
    margin: 0 auto;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      margin: auto;

      &.city {
        max-width: 60px;
      }
    }
  }
}

@media (min-width: 768px) {
  .footer {
    .grid {
      > div {
        border-left: 5px solid $gold;
        text-align: left;
        padding-left: 10px;
        line-height: 1.8rem;
        padding-bottom: 15px;
      }
    }
  }
}


