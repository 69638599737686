
section.video {
  .video-wrapper {
    border: 5px solid $gold;
    text-align: center;
    position: relative;
    display: block;
    width: calc(100% - 10px);
    max-width: 600px;
    padding: 0;
    overflow: hidden;
    margin: 0 auto;

    &::before {
      padding-top: 56.25%;
      display: block;
      content: "";
    }

    iframe,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  h2 {
    img {
      width: 250px;
      height: auto;
      display: block;
      margin: 0 auto;
      //transform: translateY(-40px);
    }
  }

  .citazione {
    margin-bottom: 75px;
    line-height: 1;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    font-weight: 700;
    font-style: italic;
  }

  @media screen and (max-width: 767px) {
    .citazione br.desktop {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    .citazione br.mobile {
      display: none;
    }
  }

  .press-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(50px, 1fr));

    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    grid-gap: 20px;
    margin-top: 40px;

    a {
      border: none;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        width: 28%;

        &:nth-child(1) {order: 1;}
        &:nth-child(2) {order: 2;}
        &:nth-child(3) {order: 99;}
        &:nth-child(4) {order: 3;}
        &:nth-child(5) {order: 99;}
        &:nth-child(6) {order: 99;}
        &:nth-child(7) {order: 99;}
        &:nth-child(8) {order: 6;}
        &:nth-child(9) {order: 99;}
        &:nth-child(10) {order: 99;}
        &:nth-child(11) {order: 5;}
        &:nth-child(12) {order: 4;}
        &:nth-child(13) {order: 99;}
        &:nth-child(14) {order: 99;}
        &:nth-child(15) {order: 99;}
      }

      @media screen and (min-width: 768px) {
        &:last-of-type {
          display: none;
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border: none;
    }
  }
}